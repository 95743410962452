import {
  Photo,
  Image,
  ImageNode,
  ProductVariant,
  AllProductVariants,
} from "../../types"

export function convertImageNode(
  node: ImageNode,
  productVariants: AllProductVariants
): Photo {
  return {
    id: node.id,
    type: "Photo",
    handle: node.handle!,
    feature: convertPhoto(node),
    title: node.title!,
    excerpt: node.excerpt?.excerpt!,
    productVariant: convertProductVariant(node, productVariants),
    previewPhotos:
      node.previewPhotos?.map(previewPhoto =>
        convertPreviewPhoto(previewPhoto)
      ) || [],
  }
}

export function convertProductVariant(
  node: ImageNode,
  productVariants: AllProductVariants
): ProductVariant | null {
  if (node.product == null) {
    return null
  }

  const variantNode = productVariants.edges
    .map(edge => edge.node)
    .find(variantNode => variantNode.shopifyId === node.product)

  if (variantNode == null) {
    throw new Error("Could not find variant")
  }

  variantNode

  return {
    id: convertProductVariantId(node.product),
    type: "ProductVariant",
    amount: variantNode.priceV2?.amount!,
    currency: variantNode.priceV2?.currencyCode!,
    availableForSale: variantNode.availableForSale!,
  }
}

export function convertPhoto(node: ImageNode): Image {
  return {
    type: "Image",
    description: node.image!.description!,
    src: node.image!.fluid!.src!,
    srcWebp: node.image!.fluid!.srcWebp!,
    srcSet: node.image!.fluid!.srcSet!,
    srcSetWebp: node.image!.fluid!.srcSetWebp!,
    originalDimensions: {
      width: node.image!.file!.details!.image!.width!,
      height: node.image!.file!.details!.image!.height!,
    },
  }
}

export function convertPreviewPhoto(
  node: NonNullable<ImageNode["previewPhotos"]>[0]
): Image {
  if (node == null) {
    throw new Error("No image found")
  }

  return {
    type: "Image",
    src: node.image!.fluid!.src!,
    srcWebp: node.image!.fluid!.srcWebp!,
    srcSet: node.image!.fluid!.srcSet!,
    srcSetWebp: node.image!.fluid!.srcSetWebp!,
    originalDimensions: {
      width: node.image!.file!.details!.image!.width!,
      height: node.image!.file!.details!.image!.height!,
    },
  }
}

function convertProductVariantId(id: string) {
  return typeof atob != "undefined"
    ? atob(id)
        .split("/")
        .pop()
    : undefined
}
