import React from "react"
import { graphql } from "gatsby"

import { SEO, Slideshow } from "../components"
import { ImageTemplateContext, ImageQueryData } from "../types"
import { isServer, convertImageQuery } from "../utilities"
import { useRealViewportUnits } from "../hooks"

interface Props {
  pageContext: ImageTemplateContext
  data: ImageQueryData
  location: Window["location"]
}

const ImageTemplate = ({ data, location }: Props) => {
  useRealViewportUnits()

  const { photo } = convertImageQuery({ data })

  const meta =
    photo.productVariant == null
      ? [
          {
            property: "og:type",
            content: "website",
          },
        ]
      : [
          {
            property: "og:type",
            content: "product",
          },
          {
            property: "og:price:amount",
            content: photo.productVariant.amount,
          },
          {
            property: "og:price:currency",
            content: photo.productVariant.currency,
          },
        ]

  const description =
    photo.excerpt == null ? undefined : photo.excerpt.replace(/\n/g, " ")

  return (
    <>
      <SEO
        images={[photo.feature]}
        meta={meta}
        description={description}
        title={photo.title}
        path={location.pathname}
      />
      {isServer() ? null : <Slideshow items={[photo]} action="fullscreen" />}
    </>
  )
}

export default ImageTemplate

export const query = graphql`
  query Image($imageId: String!) {
    contentfulImage(id: { eq: $imageId }) {
      id
      handle
      excerpt {
        excerpt
      }
      product
      title
      image {
        description
        fluid(quality: 50, maxHeight: 2000, maxWidth: 2000) {
          srcSet
          srcSetWebp
          src
          srcWebp
        }
        file {
          details {
            image {
              height
              width
            }
          }
        }
      }
      previewPhotos {
        image {
          fluid(quality: 50, maxHeight: 2000, maxWidth: 2000) {
            src
            srcSet
            srcSetWebp
            srcWebp
          }
          file {
            details {
              image {
                height
                width
              }
            }
          }
        }
      }
    }
    allShopifyProductVariant {
      edges {
        node {
          shopifyId
          priceV2 {
            amount
            currencyCode
          }
          availableForSale
        }
      }
    }
  }
`
