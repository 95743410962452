import { ImageQueryData, Photo } from "../../types"

import { convertImageNode } from "./common"

interface Input {
  data: ImageQueryData
}

interface Output {
  photo: Photo
}

export function convertImageQuery({ data }: Input): Output {
  if (data.contentfulImage == null) {
    throw new Error("No image found")
  }

  return {
    photo: convertImageNode(
      data.contentfulImage,
      data.allShopifyProductVariant
    ),
  }
}
